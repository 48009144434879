/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';
// Vue ElementUI
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import {
  PrimaryButton,
  SecondaryButton,
} from './components/elements/button/style';
import { StyledContainer } from './components/elements/layout/style';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import './styles/app.scss';
// import style (<= Swiper 5.x)
import 'swiper/swiper-bundle.css';



Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI, {
  locale,
});

// VueRouter
import VueRouter from 'vue-router';
import routes from './routes';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
Vue.use(VueRouter);
Vue.component('primary-button', PrimaryButton);
Vue.component('secondary-button', SecondaryButton);
Vue.component('fc-styled-container', StyledContainer);
Vue.component(
  'fc-form-input',
  require('./components/elements/input/input.vue').default
);
Vue.component(
  'online-banking-icon',
  require('./components/elements/svg/online-banking.vue').default
);
Vue.component(
  'statement-upload-icon',
  require('./components/elements/svg/statement-upload.vue').default
);

Vue.component(
  'loading-spinner',
  require('./components/elements/svg/loading.vue').default
);
Vue.component(
  'button-loading-spinner',
  require('./components/elements/svg/button-loading.vue').default
);

Vue.component('online-error', require('./views/OnlineError.vue').default);

const router = new VueRouter({
  mode: 'history',
  routes,
});

if (['prod', 'dev'].includes(process.env.VUE_APP_ENV)) {
    Sentry.init({
        Vue,
        environment: process.env.VUE_APP_ENV,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [process.env.VUE_APP_GATHR_ENDPOINT, process.env.VUE_APP_ENGINE_URL, /^\//],
          }),
        ],
        debug: process.env.VUE_APP_ENV  !==  'prod',
        tracesSampleRate: Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
        tracingOptions: {
            trackComponents: true,
            hooks: ["mount", "update", "destroy", "create"],
            timeout: 2000,
        },
        // Vue specific
        logErrors: process.env.VUE_APP_ENV !== 'prod',
        attachProps: true,
        attachStacktrace: true,
      });
}


import App from './views/App';
import { store } from './store';

new Vue({
  el: '#app',
  components: {
    app: App,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || '';
      },
    },
  },
  template: '<app></app>',
  store,
  router,
});
