<template>
    <div class="_spa-container" style="margin-bottom: 350px">
        <div class="_statement-content">
            <div>
                <div class="_wording-container">
                    <span class="_title">Tell us about your Primary Income</span>
                </div>
                <el-form :model="personalForm" ref="requirements" :rules="rules" @submit.native.prevent="preventSubmit" style="padding-bottom: 20px;">
                    <div class="_bank-radios _income-radios" style="margin-top:10px">
                        <span style="color: #A8ACB8 !important;font-size: 16px !important;margin-top: 30px;display: block;margin-bottom: 10px;">Select your main source of income</span>
                        <el-radio-group v-model="personalForm.occupation">
                                <el-radio-button 
                                    v-for="item in occupations"
                                    id="selector"
                                    :selection="item.value"
                                    :label="item.value"
                                    :key="item.value"
                                    :value="item.value"
                                    style="margin-bottom: 0px;"
                                >
                                <span style="margin-left: 5px;">{{item.label}}</span>
                                </el-radio-button>
                            </el-radio-group>
                    </div>
                    <div class="_bank-radios _income-radios" style="margin-top:10px">
                        <span style="color: #A8ACB8 !important;font-size: 16px !important;display: block;margin-bottom: 10px;">How often do you get paid?</span>
 
                        <el-radio-group v-model="personalForm.payment_frequency">
                            <el-radio-button 
                                v-for="item in payment_frequencys"
                                id="selector"
                                :selection="item.value"
                                :label="item.value"
                                :key="item.value"
                                :value="item.value"
                                style="margin-bottom: 0px;"
                            >
                            <span style="margin-left: 5px;">{{item.label}}</span>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                    <fc-form-input
                        label="What is your gross income?"
                        :tooltip="'Gross Income refers to the total amount of income you earn before taxes and other deductions. It is not just your salary or wage but includes other forms of income you receive such as: tips, rental payments, maintenance payments, pension and interest.'"
                        prop="gross_income"
                        :error="errors.gross_income"
                        v-model="personalForm.gross_income"
                        :currency="true"
                        inputmode="numeric"
                        type="tel"
                        pattern="[0-9]*"
                        placeholder="10000.00"
                        name="gross_income"
                    ></fc-form-input>
                    <fc-form-input
                        v-if="type == 'get-quote'"
                        label="What is your monthly expenses?"
                        prop="expenses"
                        :error="errors.expenses"
                        v-model="personalForm.expenses"
                        :currency="true"
                        inputmode="numeric"
                        type="tel"
                        pattern="[0-9]*"
                        placeholder="10000.00"
                        name="expenses"
                    ></fc-form-input>
                </el-form>
                
            </div>
            <div>
                <div style="display: flex;width: 100%;justify-content: space-between;">
                    <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px"  @click="submitPersonalIncome" :disabled="((type == 'get-quote'&& !expenses) || type == 'online') || !gross_income || !occupation || !payment_frequency">Next</primary-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields, validateAmount } from '../helpers.js' 
export default {
    data(){
        return {
            errors:{},
            payment_frequencys: [
                {
                    value: 'weekly',
                    label: 'Weekly'
                },
                {
                    value: 'monthly',
                    label: 'Monthly'
                },
                {
                    value: 'commission',
                    label: 'Commission'
                },
            ],
            occupations: [
                {
                    value: 'salary',
                    label: 'Salary'
                },
                {
                    value:'wage',
                    label:'Wage'
                },
                {
                    value: 'sassa',
                    label: 'SASSA Grant'
                },
                {
                    value: 'self-employed',
                    label: 'Self-employed'
                },
                {
                    value: 'commission',
                    label: 'Commission'
                },
                {
                    value: 'unemployed',
                    label: 'Unemployed'
                }
            ],
            rules: {
                gross_income: [{
                    required: true,
                    validator: validateAmount,
                    trigger: 'blur'
                }],
                expenses: [{
                    required: true,
                    validator: validateAmount,
                    trigger: 'blur'
                }],
                occupation: [
                    {
                        required: true,
                        message: "Please select an option",
                        trigger: "blur",
                    },
                ],
                payment_frequency: [
                    {
                        required: true,
                        message: "Please select an option",
                        trigger: "blur",
                    },
                ],
            },
        }
    },
    computed:{
        ...mapState({
            personalForm: state => state.application.personalLoan,
            type:state=>state.application.type
        }),
        ...mapFields({
            fields: ['payment_frequency','occupation','gross_income','expenses'],
            store: 'application',
            fieldMap: 'personalLoan',
            target: 'personalForm',
            mutation: 'updatePersonalLoanField'
        }),
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: primary income',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    methods:{
        preventSubmit(){
            this.submitPersonalIncome();
        },
        submitPersonalIncome(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: primary income',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: next',
                event_label: 'income source period',
                event_value: this.personalForm.occupation + ', ' + this.personalForm.payment_frequency,
                smme_monthly_income: this.personalForm.gross_income,
            });
            this.$refs['requirements'].validate(async valid => {
                if(valid){
                    if(this.personalForm.occupation == 'unemployed'){
                        await this.$store.dispatch('state/updateStatus', 'unemployed-error')
                        this.$router.push({name:'unemployed'})
                    }else{
                        await this.$store.dispatch('state/updateStatus', 'personal-information')
                        this.$router.push({name:'personal-information'})
                    }
                }else{
                    window.scrollTo(0, 0);
                    return false;
                }
            })
        },
        checkEnter(){
            this.submitPersonalIncome()
        }
    }
}
</script>