<template>
    <div class="_spa-container">
        <div class="_wording-container">
            <span class="_title">Choose application method</span>
        </div>
        <div class="_custom-radio">
            <div v-for="item in statement_types" :key="item.value" class="_radio" id="selector" :selection="item.value">
                <div class="_recomended-tag" v-if="item.promote">Recommended</div>
                <div class="_text-section">
                    <img class="_main-img" v-if="item.value == 'apply-now'" src="/assets/spa/apply-now.svg"/>
                    <img class="_main-img" v-else-if="item.value == 'get-quote'" src="/assets/spa/document.svg"/>
                    <span>{{item.label}}</span>
                </div>
                <div>
                    <img class="_tick-svg" src="/assets/spa/tick-on.svg" v-if="item.value == type" @click="updateRedirect(item.value)"/>
                    <img class="_tick-svg" src="/assets/spa/tick-off.svg" v-else @click="updateRedirect(item.value)"/>
                </div>
            </div>
        </div>
        <div style="color:#A8ACB8; text-align:center;">
            <span>
                Complete the “Apply Now” method and access your loan immediately.
                If you're not ready to upload your documents yet, choose "Get Quote" to get an indication of the loans you qualify for.
            </span>
        </div>
        <div style="color:#A8ACB8; text-align:center;">
            <span>
                By clicking “Next” you agree to the VodaPay <a href="https://www.vodacom.co.za/vodacom/terms/vodapay-platform">Terms and Conditions</a>
            </span>
        </div>
        <div class="_button-container">
            <secondary-button id="secondary_nav" @click="redirectToFaqs" style="width:40%">FAQ's</secondary-button>
            <primary-button id="primary_nav" @click="startApplication" style="width: 60%;justify-content: center;margin-left:10px" :disabled="!banksForm.type || noConsent">
                <!-- <i v-if="loading" class="el-icon-loading"></i> -->
                <button-loading-spinner v-if="loading"/> 
                <span v-else>Next</span>  
            </primary-button>
        </div>

        <el-dialog
            :visible.sync="onlineDialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">In order to complete the Apply Now method you need to have Online Banking </span>
            <secondary-button @click="startApplication" style="width:100%">
                 <button-loading-spinner v-if="loading2"/> 
                 <span v-else>
                     I have Online Banking<img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/>
                 </span>
                </secondary-button>
            <secondary-button @click="cancelOnlineBanking" style="width:100%;color:#C9CCD4">
                    I don't have Online Banking
                    <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/>
            </secondary-button>
        </el-dialog>

        <el-dialog
            :visible.sync="closeDialog"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">Please tap the exit icon on the top right of your screen to go to the VodaPay app. </span>
            <secondary-button @click="closeDialog = false" style="width:100%;color:#C9CCD4">Got it <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </el-dialog>
    </div>

</template>
<script>
import { mapGetters, mapState } from 'vuex'
import * as Sentry from "@sentry/vue";

export default {

    data() {
        return {
            banksForm: {
                type:'apply-now'
            },
            closeDialog:false,
            onlineDialogVisible:false,
            step:1,
            noConsent:false,
            statement_types:[
                {label:'Apply Now', value:'apply-now', promote:true},
                {label:'Get Quote', value:'get-quote'},
            ],
            isMiniApp:false,
            loading:false,
            loading2:false,
            selectedBank:{
                online_banking:'Yes',
            },
            type:'apply-now'

        }
    },
    computed: {
        ...mapGetters('state', {
            status: 'status',
            banks: 'banks'
        }),
        activeBanks(){
            let banks = this.banks;
            let res = banks.filter((b)=>b.active == 'Yes' && b.online_banking == 'Yes');
            return res;
        },
        ...mapState({
            initial_popi: state => state.application.personalLoan.initial_popi,
        }),
    },
    created() {
        window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: customer declarations',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
            cid: this.$route.query.cid,
        });
    },
    mounted(){
        let self = this;
        let isMiniApp = false;
        try{
            // eslint-disable-next-line no-undef
            if(my){
                isMiniApp = true
            }
        }catch{
         isMiniApp = false   
        }
        // isMiniApp = false
        if(isMiniApp){
        // eslint-disable-next-line no-undef
            my.onMessage = async function (e) {
                try {
                    if(e.type == 'USER_DETAILS'){
                        Sentry.captureMessage(e.type, {
                            tags: {
                                section: "ApplicationMethod - USER_DETAILS: OAuth response"
                            }
                        });
                        let details = {}
                        if(e.user && Array.isArray(e.user.contactInfos)){
                            e.user.contactInfos.forEach(element => {
                                if(element.contactType == 'EMAIL'){
                                    details.email = element.contactNo;
                                }
                                if(element.contactType == 'MOBILE_PHONE'){
                                    details.phone_number = '0'+element.contactNo.substr(3,element.contactNo.length)
                                }
                            });
                        }
    
                        details.first_name = e.user && e.user.userName && e.user.userName.firstName ? e.user.userName.firstName : '';
                        details.last_name = e.user && e.user.userName && e.user.userName.lastName ? e.user.userName.lastName : '';
                        if(e.user && e.user.certificates && e.user.certificates[0].certificateNo){
                            details.id_number = e.user.certificates[0].certificateNo;
                        }
                        await self.$store.dispatch('application/updatePersonalLoanField',{
                            key:'cell_phone_number',
                            value: details.phone_number
                        })
                        await self.$store.dispatch('application/updatePersonalLoanField',{
                            key:'email',
                            value: details.email
                        })
                        await self.$store.dispatch('application/updatePersonalLoanField',{
                            key:'first_name',
                            value: details.first_name
                        })
                        await self.$store.dispatch('application/updatePersonalLoanField',{
                            key:'last_name',
                            value: details.last_name
                        })
                        await self.$store.dispatch('application/updatePersonalLoanField',{
                            key:'id_number',
                            value: details.id_number
                        })
                        await self.$store.dispatch('application/updateType', self.type)
                        await self.$store.dispatch('state/updateStatus', 'loan-information')
                        await self.$router.push({ name: 'loan-information' })
                        self.loading2 = false;
                    }
                    if(e.type == "FILE_UPLOADED"){
                        Sentry.captureMessage(e.type, {
                            tags: {
                                section: "ApplicationMethod - FILE UPLOADED RESPONSE"
                            }
                        });        
                    }
                    if(e.type == "ERROR_UPLOAD"){
                        Sentry.captureMessage(e.type, {
                            tags: {
                                section: "ApplicationMethod - ERROR_UPLOAD: FILE UPLOADED ERROR RESPONSE"
                            }
                        });
                    }
                } catch (error) {
                        Sentry.captureException(error, {
                            tags: {
                                section: "ApplicationMethod - OAuth response"
                            }
                        });
                    console.log(error)
                }

            };
        }
        this.isMiniApp = isMiniApp;
    },
    methods: {
        initiate(){
            let token = process.env.VUE_APP_GATHR_KEY;
            // eslint-disable-next-line no-undef
            my.postMessage({
                code:"INITIATE_UPLOAD",
                token:token,
                id:'48e8d6b9-4acb-496d-b4c9-b33d21ddb37e'
            });
        },
        updateRedirect(item){
            this.type = item
        },
        async startApplication() {
            window.utag.link({
                event_name: 'ui_interaction, journey_start',
                page_name: 'vodapay: vodalend compare: choose application method',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: next',
                event_label: 'application method',
                event_value: this.type,
                transaction_method: this.type,
                journey_name: this.type,
            });
            if(!this.loading){
                try {
                    this.loading2 = true;
                    if(!this.isMiniApp){
                        await this.$store.dispatch('application/updateType', this.type)
                        await this.$store.dispatch('state/updateStatus', 'loan-information')
                        await this.$router.push({ name: 'loan-information' })
                    }else{
                        // eslint-disable-next-line no-undef
                        my.postMessage({
                            code:"FETCH_DETAILS",
                        });
                    }

                } catch (e) {
                    console.log(e)
                }
            }

        },

        cancel(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: customer declarations',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: cancel',
            });
            this.closeDialog = true
            this.noConsent = true;
        }, 
        redirectToFaqs(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: choose application method',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: faqs',
            });
            this.$router.push('faqs/online')
        },
        async setInitialPopi(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: customer declarations',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: allow & continue',
            });
            await this.$store.dispatch('application/updatePersonalLoanField',{
                key:'initial_popi',
                value: true
            })
            window.utag.view({
                event_name: 'page_view',
                page_name: 'vodapay: vodalend compare: choose application method',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
            });
        },
        openOnlineBankingDialog(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: choose application method',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: next',
                event_label: 'application method',
                event_value: this.type,
            });
            if(this.type == 'get-quote'){
                this.startApplication()
            }else{
                this.onlineDialogVisible = true;
                 window.utag.view({
                    event_name: 'page_view',
                    page_name: 'vodapay: vodalend compare: online banking',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                });
            }
        },
        cancelOnlineBanking(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: online banking',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: I dont have online banking',
            });
            this.onlineDialogVisible = false;
            this.type = 'get-quote'
        }
    }
}
</script>