<template>
    <div class="_spa-container">
        <div class="_loading-screens" v-if="loadingSpinner">
            <loading-spinner :width="100"/>
        </div>
        <div style="height: calc(-25px + 100vh); overflow: hidden; margin-left: -10px; width: 100vw; display: flex; justify-content: flex-end;flex-direction: column; align-items: end;" v-if="consentVisible">
            <div class="hid-box" style="position: absolute; width: 100vw; left: 0; bottom: 0;;">
                <span style="color: #C9CCD4;font-size: 20px;text-align: center;margin-top: 10px;"> In order for us to present your options, please give us consent to:</span>
                <span style="margin-top: 40px;display: flex;width: 100%;"><img src="/assets/spa/caution.svg" style="width: 25px;margin-right: 10px;"/>Perform a credit enquiry with a credit bureau</span>
                <div class="divider" style="width: 100%;border-bottom: 1px solid #A8ACB8;margin-top: 20px;"></div>
                <div class="_button-container _fetching-buttons" style="width:100%;margin-top:50px">
                    <secondary-button id="secondary_nav" style="width:40%;font-size:17px" @click="cancel" :disabled="fetchingOffers">Cancel</secondary-button>
                    <primary-button id="primary_nav" style="width: 60%;justify-content: center;margin-left: 10px;margin-bottom: 10px;" @click="fetchOffers()" :disabled="fetchingOffers">
                        <button-loading-spinner v-if="fetchingOffers"/> 
                        <span v-else style="font-size:17px">Allow & continue</span>
                    </primary-button>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center;font-size:16px;font-weight: 600;">Are you sure?</span>
            <span style="text-align:center;font-size: 14px;font-weight: 400;">Are you sure you want to cancel your application?</span>
            <span style="text-align:center;font-size: 14px;font-weight: 400;">If you’re sure you want to cancel, tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <secondary-button id="secondary_nav" @click="fetchOffers(true)" style="width:100%;font-size:16px"> I want to continue<img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </el-dialog>
    </div>
</template>

<script>

export default {
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: credit enquiry permission',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    data(){
        return {
            dialogVisible:false,
            consentVisible:true,
            errors:{},
            loadingSpinner:false,
            open:true,
            fetchingOffers:false
        }
    },
    methods:{
        cancel(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: credit enquiry permission',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: cancel',
            });
            this.consentVisible = false
            this.dialogVisible = true
        },
        async fetchOffers(modalFlow = false){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: credit enquiry permission',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: allow & continue',
            });
            if(modalFlow){
                this.dialogVisible = false
                this.loadingSpinner = true
            }else{
                this.fetchingOffers = true;
            }
            this.$router.push({name:'employment'})
        },
        redirectToFaqs(){
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push('faqs/credit')
        },


    },

}
</script>