<template>
    <div class="_spa-container">
        <div style="height: calc(100vh + 62px); overflow: hidden;">
            <div class="animated-content _statement-content" style="padding:0px;height:auto">
                <div>
                    <div class="_wording-container" v-if="repeatBankAccounts.length == 0 || cancelRepeat">
                        <span class="_title">Please select your bank</span>
                        <span class="_text">Please select the bank account where you receive your main income, and pay your expenses from.</span>
                    </div>
                    <div class="_wording-container" v-else>
                        <span class="_title">Please select your bank</span>
                        <span class="_text">We have the following banks on your profile from a previous application. Please select which one you'd like to continue with.</span>
                    </div>
                    <el-form :model="personalForm" ref="requirements" >
                        <div class="_bank-radios _bank-images">
                            <el-radio-group v-model="personalForm.bank">
                                <el-radio-button 
                                    v-for="item in filteredBanks"
                                    :label="item.slug"
                                    :key="item.slug"
                                    :value="item.slug"
                                    style="margin-bottom: 0px;"
                                    id="selector"
                                    :selection="item.slug"
                                >
                                    <img v-if="item.slug == 'capitec'" :src="'/assets/banks/small-logos/capitec-square.jpg'" style="width:25px;max-height: 25px;margin-top: 10px;margin-bottom: 10px; border-radius:20px"/>
                                    <img v-else-if="item.slug == 'standard-bank'" :src="'/assets/banks/small-logos/stdbank-square.jpg'" style="width:25px;max-height: 25px;margin-top: 10px;margin-bottom: 10px; border-radius:20px"/>
                                    <img v-else :src="'/assets/banks/small-logos/'+item.slug+'.svg'" style="width:25px;max-height: 25px;margin-top: 10px;margin-bottom: 10px;"/>
                                    <div style="width: 100%;display: flex;align-items: center;justify-content: center;max-width: 90px;">
                                        <p style="width: 58px;margin-bottom: 0px;white-space: pre-wrap;text-align: left;line-height: 15px;">{{item.name}}</p>
                                    </div>
                                    
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </el-form>
                </div>

                <div>
                    <div class="_secured-container">
                        <div class="secured-spacer">
                            <div class="_divider"></div>
                            <img src="/assets/spa/shake.svg"/>
                            <div class="_divider"></div>
                        </div>
                        <span>Bank statements are used to match you with a suitable lender.This process is fully encrypted and secure.</span>
                    </div>
                    <div style="display: flex;width: 100%;justify-content: space-between;">
                        <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px" :disabled="!bank" @click="submitBank">Next</primary-button>
                    </div>
                </div>
            </div>
            <div id="overlay" :style="open ? 'display:block' : 'display:none'"></div>
            <div class="hid-box" :style="open ? 'top:'+top+';    border-radius: 35px 35px 0px 0px;height:auto;z-index: 2;padding-top:18px;padding-right: 20px;padding-left: 20px;' : 'top: 100%;z-index: 2;height:autoborder-radius: 35px 35px 0px 0px;padding-top:18px;padding-right: 20px;padding-left: 20px;'" v-if="!valid"> 
                <span class="material-icons" style="color: #C9CCD4;width: 100%;text-align: right;" @click="open = false">
                    close
                </span>
                <span style="text-align:center">The following bank statements are still valid from your previous application: </span>
                <div v-for="(item,index) in repeatData.requiredMonths" id="selector" :selection="item.name" v-bind:key="index" style="width:100%">
                    <span style="margin-top: 20px;display: flex;width: 100%;align-items: center;">
                        <img src="/assets/spa/round-success.svg" v-if="item.fullMonth" style="width:20px"/>
                        <img src="/assets/spa/warning.svg" v-else style="width:20px"/>
                        <span style="margin-left:20px">{{item.month}} {{item.year}}</span>
                    </span>
                    <div class="divider" style="width: 100%;border-bottom: 1px solid #A8ACB8;margin-top: 20px;"></div>
                </div>

                <div style="width:100%">
                    <span style="margin-top: 20px;display: flex;width: 100%;align-items: center;">
                        <img src="/assets/spa/info.svg" style="width:20px"/>
                        <span style="margin-left:20px">{{repeatData.message}}</span>
                    </span>
                    <div class="divider" style="width: 100%;border-bottom: none;margin-top: 20px;"></div>
                </div>
                <div style="width:100%">
                    <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px" :disabled="!bank" @click="loginRepeatBank">
                         <button-loading-spinner v-if="loading"/> 
                        <span v-else>Online Login</span>
                    </primary-button>
                    <secondary-button id="secondary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px; margin-top:10px;margin-bottom:20px" :disabled="loading" @click="setNewAccount">
                        <span>Use a new account</span>
                    </secondary-button>

                </div>
            </div>
            <div class="hid-box" :style="open ? 'top:'+top+';    border-radius: 35px 35px 0px 0px;height:auto;z-index: 2;padding-top:18px;padding-right: 20px;padding-left: 20px;' : 'top: 100%;z-index: 2;height:autoborder-radius: 35px 35px 0px 0px;padding-top:18px;padding-right: 20px;padding-left: 20px;'" v-else> 
                <span class="material-icons" style="color: #C9CCD4;width: 100%;text-align: right;" @click="open = false">
                    close
                </span>
                <img src="/assets/spa/round-success.svg" style="width: 50px;padding-top: 20px;"/>
                <span style="text-align:center">You’re up to date</span>

                <div style="width:100%">
                    <span style="margin-top: 20px;display: flex;width: 100%;align-items: center;">
                        <span style="  text-align: center;color: #C9CCD4;font-size: 16px;padding-left: 10px;padding-right: 10px;">Your data is all still valid. We have 3 months’ bank statements from your previous application which we can use. You can continue to the next step of the application.</span>
                    </span>
                    <div class="divider" style="width: 100%;border-bottom: none;margin-top: 20px;"></div>
                </div>
                <div style="width:100%">
                    <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px" :disabled="!bank" @click="continueApplication">
                        <button-loading-spinner v-if="loading"/> 
                        <span v-else>Continue with application</span>
                    </primary-button>
                    <secondary-button id="secondary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px; margin-top:10px;margin-bottom:20px" :disabled="loading"  @click="setNewAccount">
                        <span>Use a new account</span>
                    </secondary-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapFields } from '../helpers';
import * as Sentry from "@sentry/vue";
export default {
    data(){

        return {
            errors:{},
            open:false,
            top:'-61%',    
            months:[],
            sections:{},
            valid:false,
            requiredMonths:{},
            loading:false,
            repeatData:{
                requiredMonths:[],
                message:''
            },
            selectedBank:{},
            cancelRepeat:null,
        }
    },
    computed:{
        ...mapState({
            personalForm: state => state.application.personalLoan,
            repeatBankAccounts: state => state.banking.repeatBankAccounts
        }),
        ...mapGetters('state', {
            banks: 'banks',
        }),
        ...mapFields({
            fields: ['bank'],
            store: 'application',
            fieldMap: 'personalLoan',
            target: 'personalForm',
            mutation: 'updatePersonalLoanField'
        }),
        filteredBanks() {
            if (this.cancelRepeat == true){
              return this.banks.filter((b) => b.active === 'Yes')
            } else {
                if(this.repeatBankAccounts.length > 0){
                    let repeatBanks = [];
                    this.repeatBankAccounts.forEach((b)=>{
                        repeatBanks.push(b.bank)
                    });
                    let banks = this.banks.filter((b)=>b.active === 'Yes' && repeatBanks.includes(b.slug));
                    return banks
                } else {
                    let banks = this.banks.filter((b)=>b.active === 'Yes')
                    return banks;
                }   
            }
        }
    },
    created() {
        window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: bank selection',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    mounted(){
    },
    methods:{
        async submitBank(){
            if(this.cancelRepeat || this.repeatBankAccounts.length == 0){
                window.utag.link({
                    event_name: 'ui_interaction',
                    page_name: 'vodapay: vodalend compare: bank selection',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                    link_id: 'vodapay: vodalend compare: next',
                    event_label: 'bank selected',
                    event_value: this.personalForm.bank,
                    lending_bank_name: this.personalForm.bank,
                });

                for (const bank of this.banks) {
                  if(bank.slug === this.personalForm.bank) {
                    await this.$store.dispatch('application/updateSelectedBank', bank)
                  }
                }

                await this.$router.push({ name: 'select-upload' })
            } else {
                let requiredMonths = this.getLastMonths(3)
                this.repeatData = {
                    requiredMonths:[],
                    message:''
                }
                let banks = this.repeatBankAccounts;
                banks.forEach(b => {
                    if(b.bank == this.personalForm.bank){
                        this.selectedBank = b;
                    }
                });
                let currentStatements = this.selectedBank.summary.months;
                let lastTxDate = false;
                requiredMonths.forEach(m => {
                    currentStatements.forEach(s => {
                        if(s.month == m.month && s.year == m.year){
                            if(s.fullMonth){
                                m.fullMonth = true
                            }else{
                                m.fullMonth = false;
                                m.lastTransactionDate = this.selectedBank.summary.averages.last_transaction
                                lastTxDate = m.lastTransactionDate;
                            }
                        }
                    });

                });
                let fullMonthsCount = requiredMonths.filter((m)=>m.fullMonth).length;
                let invalidMonths = requiredMonths.filter((m)=>!m.fullMonth);
                let message = '';
                if(fullMonthsCount == 3){
                    this.valid = true
                }else if(invalidMonths.length == 3){
                    console.log(invalidMonths)
                    /**
                     * Case for when bank account is to old
                     */
                }else if(invalidMonths.length == 2){
                    let to = invalidMonths[0];
                    let from = invalidMonths[1];
                    message = 'We only have transaction data up until '+lastTxDate+'. Please login to your online banking in order to retrieve your bank statement for '+from.month+' '+from.year+' and '+to.month+' '+to.year
                    this.top = '-62%'
                }else if (invalidMonths.length == 1){
                    let to = invalidMonths[0]
                    console.log(to)
                    message = 'Please login to your online banking in order to retrieve your bank statement for '+to.month + ' '+to.year
                    this.top = '-56%'
                }
                if(this.valid){
                    this.top = '-46%'
                }
                let repeatData = {
                    requiredMonths: requiredMonths,
                    message
                }
                this.repeatData = repeatData
                this.open = true;
            }

            // this.$router.push({name:'select-upload'})
        },
        getLastMonths(n) {
            let m =['','January','February','March','April','May','June','July','August','September','October','November','December'];
            let months = new Array();

            let today = new Date();
            let year = today.getFullYear();
            let month = today.getMonth();

            let i = 0;
            do {
                months.push({
                    month:m[parseInt((month > 9 ? "" : "0") + month)],
                    year,
                    fullMonth:false
                });
                if (month == 1) {
                    month = 12;
                    year--;
                } else {
                    month--;
                }
                i++;
            } while (i < n);
            return months;
        },
        async loginRepeatBank(){
            let res = await this.setRepeatAccount();
            if(res){
                this.$router.push({name:'online-banking'})
            }else{
                this.$router.push({name:'technical-error'})
            }

        },

        setNewAccount(){
            this.cancelRepeat = true;
            this.open = false;
        },

        async continueApplication(){
            let res = await this.setRepeatAccount();
            if(res){
                this.$router.push({name:'employment'})
            }else{
                this.$router.push({name:'technical-error'})
            }
        },

        async setRepeatAccount(){
            try {
                this.loading = true;
                let bankAccount = {
                    id:this.selectedBank.id
                }
                await this.$store.dispatch('application/setBankAccount',{ bankAccount, repeat:true })
                return true   
            } catch (error) {
                Sentry.captureException(error, {
                    tags: {
                        section: "SelectBank - Repeated Account"
                    }
                });
                console.error(error);
                return false
            }
        }
    },

}
</script>