<template>
    <div class="_spa-container">
        <div class="_loading-screens" v-if="acceptingOffer">
            <loading-spinner :width="100"/>
        </div>
        <div class="_statement-content" >
                <div>
                    <div class="_wording-container" v-if="type == 'apply-now'">
                        <span class="_title">Your loan offers</span>
                        <span class="_text">Below are personalised quotes from your best-suited lenders.</span>
                        <span></span>
                    </div>
                    <div class="_wording-container" v-else>
                        <span class="_title">Your loan estimates</span>
                        <span class="_text">These are estimates of loan offers our lenders have for you based on the amount you need. </span>
                    </div>

                    <div class="_offers">
                        <div v-for="(offer, index) in orderedPartners(offers.matches,type)" :key="offer.id">
                          <div class="__item" v-if="offer.live_score != -2 && !offer.live_score || offer.id === 116" :id="'ITEM_'+offer.id" @click="currentSlide = index">
                            <div class="__logo">
                              <template v-if="offer.company_mini_logo_url !== null ">
                                <img :src="offer.company_mini_logo_url" @error="handleImageLoadError($event)" alt="" style="padding:0px !important">
                              </template>
                              <template v-else-if="offer.company_logo_url !== null ">
                                <img :src="offer.company_logo_url" @error="handleImageLoadError($event)" alt="">
                              </template>
                              <template v-else>
                                <img src="/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg"
                                  @error="handleImageLoadError($event)" alt="">
                              </template>
                            </div>
                            <div class="_loan-info">
                                <span v-if="offer.live_score && offer.live_score.LoanAmount > 0">R{{ new Intl.NumberFormat().format(offer.live_score.LoanAmount) }}</span>
                                <span v-else>R{{ new Intl.NumberFormat().format(personalLoan.loan_amount_required) }}</span>
                                <span style="font-size: 16px;line-height: 24px;">Personal Loan</span>
                                <span class="_interest-rate" v-if="offer.live_score && offer.live_score.InterestRate > 0">Interest rate: {{offer.live_score.InterestRate}}% pa</span>
                                <span class="_interest-rate" v-else-if="offer.probability !== null">Chance of Success: {{offer.probability}}%</span>
                                <span class="_interest-rate" v-if="offer.live_score && offer.live_score.MonthlyRepayment">Installment: R{{ new Intl.NumberFormat().format(offer.live_score.MonthlyRepayment) }}</span>

                                <span class="_find-out" @click="setOffer(offer)">
                                    Find Out More
                                    <i class="material-icons">arrow_right_alt</i>
                                </span>
                            </div>
                          </div>
                            <div class="__item" v-else-if="offer.live_score != -2"  :id="'ITEM_'+offer.id" @click="currentSlide = index">
                              <div class="__logo">
                                <template v-if="offer.company_mini_logo_url !== null ">
                                  <img :src="offer.company_mini_logo_url" @error="handleImageLoadError($event)" alt="">
                                </template>
                                <template v-else-if="offer.company_logo_url !== null ">
                                  <img :src="offer.company_logo_url" @error="handleImageLoadError($event)" alt="">
                                </template>
                                <template v-else>
                                  <img src="/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg"
                                    @error="handleImageLoadError($event)" alt="">
                                </template>
                              </div>
                                <div class="_loan-info">
                                    <template v-if="offer.live_score != null">
                                        <template v-if="offer.live_score !== 'undefined' ">
                                            <span v-if="offer.live_score && offer.live_score.LoanAmount > 0">R{{ new Intl.NumberFormat().format(offer.live_score.LoanAmount) }}</span>
                                            <span v-else-if="offer.live_score && offer.live_score.min && offer.live_score.max > 0">R{{ new Intl.NumberFormat().format(offer.live_score.min) }} - R{{ new Intl.NumberFormat().format(offer.live_score.max) }}</span>
                                            <span v-else>R{{ new Intl.NumberFormat().format(personalLoan.loan_amount_required) }}</span>
                                            <span style="font-size: 16px;line-height: 24px;" v-if="offer.live_score && offer.live_score.ProductDescription">{{offer.live_score.ProductDescription}}</span>
                                            <span style="font-size: 16px;line-height: 24px;" v-else>Personal Loan</span>
                                            <span class="_interest-rate" v-if="offer.live_score.InterestRate > 0 && offer.id == 150" >Interest rate: {{ offer.live_score.InterestRate }}% Monthly</span>
                                            <span class="_interest-rate" v-else-if="offer.live_score.InterestRate > 0">Interest rate: {{ offer.live_score.InterestRate }}%</span>
                                            <template v-if="offer.live_score.TermAllowed">
                                                <span class="_interest-rate" v-if="offer.live_score.PaydayLoan">Term: {{offer.live_score.TermAllowed == 1 ?  '1 day' : offer.live_score.TermAllowed + " days"}}</span>
                                                <span class="_interest-rate" v-else>Term: {{offer.live_score.TermAllowed == 1 ?  '1 month' : offer.live_score.TermAllowed + " months"}}</span>
                                            </template>
                                            <span class="_interest-rate" v-if="offer.live_score.MonthlyRepayment">Installment: R{{ new Intl.NumberFormat().format(offer.live_score.MonthlyRepayment) }}</span>
                                            <span class="_find-out" @click="setOffer(offer)">
                                                Find Out More
                                                <i class="material-icons">arrow_right_alt</i>
                                            </span>
                                        </template>
                                    </template>
   
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="_button-container" style="padding-top: 20px;">
                    <secondary-button id="secondary_nav" @click="redirectToFaqs" style="width:100%">FAQ's</secondary-button>
                </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            edittingIncome:false,
            errors:{},
            open:false,
            acceptingOffer:false,
            partner:{},
            selectedOffer:{},
            offerModal:false,
            step:'loan_amount',
        }
    },
    computed:{
        ...mapGetters({
            personalLoan:'application/personalLoan',
            offers: 'offers/offers',
            visiblePartners:'offers/visiblePartners',
            type: 'application/type',
        }),
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: loan offers',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
            event_label: 'number of offers',
            event_action: this.offers.matches.length,
        });
    },
    methods:{
        redirectToFaqs(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: loan offers',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: faqs',
            });
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push('/faqs/credit')
        },
        async setOffer(offer){
            console.log(offer)
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: loan offers',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: find out more',
                lending_offer_name: offer.company_name,
            });
            await this.$store.dispatch('offers/setSelectedOffer', offer)
            this.$router.push({name:'finalize-offer'})
        },
        orderedPartners(partners,type){
            let newPartners = []
            for (let index = 0; index < partners.length; index++) {
                const partner = partners[index];
                if(type  == 'apply-now' && partner.end_to_end && partner.live_score){
                    if(partner.live_score && partner.live_score.offers && partner.live_score.offers.length > 0){
                        let offers = partner.live_score.offers;
                        offers.forEach((offer)=>{
                            let offerPartner = {
                                company_mini_logo_url: partner.company_mini_logo_url,
                                company_wide_logo_url: partner.company_wide_logo_url,
                                company_logo_path: partner.company_logo_path,
                                company_logo_url: partner.company_logo_url,
                                company_name: partner.company_name,
                                company_website_url: partner.company_website_url,
                                id: partner.id,
                                live_score: offer,
                                probability: partner.probability,
                                rank: partner.rank,
                                userable: partner.userable,
                            }
                            newPartners.push({...offerPartner, end_to_end:true})
                        })
                    }else{
                        newPartners.push({...partner, end_to_end:true})
                    }
                }else if(type == 'get-quote'){
                    if(partner.live_score && partner.live_score.offers && partner.live_score.offers.length > 0){
                        let offers = partner.live_score.offers;
                        offers.forEach((offer)=>{
                            let offerPartner = {
                                company_mini_logo_url: partner.company_mini_logo_url,
                                company_wide_logo_url: partner.company_wide_logo_url,
                                company_logo_path: partner.company_logo_path,
                                company_logo_url: partner.company_logo_url,
                                company_name: partner.company_name,
                                company_website_url: partner.company_website_url,
                                id: partner.id,
                                live_score: offer,
                                probability: partner.probability,
                                rank: partner.rank,
                                userable: partner.userable,
                            }
                            newPartners.push({...offerPartner, end_to_end:false})
                        })
                    }else{
                        newPartners.push({...partner,end_to_end:false })
                    }
                }

            }
            let partnerCount = newPartners.filter(
                function(offer) {
                return offer.live_score != -2;
                }
            ).length
            if (partnerCount == 0 && type == "apply-now"){
                for (let index = 0; index < partners.length; index++) {
                        const partner = partners[index];
                        if(partner.live_score && partner.live_score.offers && partner.live_score.offers.length > 0){
                            let offers = partner.live_score.offers;
                            offers.forEach((offer)=>{
                                let offerPartner = {
                                    company_mini_logo_url: partner.company_mini_logo_url,
                                    company_wide_logo_url: partner.company_wide_logo_url,
                                    company_logo_path: partner.company_logo_path,
                                    company_logo_url: partner.company_logo_url,
                                    company_name: partner.company_name,
                                    company_website_url: partner.company_website_url,
                                    id: partner.id,
                                    live_score: offer,
                                    probability: partner.probability,
                                    rank: partner.rank,
                                    userable: partner.userable,
                                }
                                newPartners.push({...offerPartner, end_to_end:false})
                            })
                        }else{
                            newPartners.push({...partner,end_to_end:false })
                        }
                }            
            }
            partners = newPartners
            partners = partners.filter(partner => partner.live_score !== -2);
            return _.orderBy(partners, 'rank');
        }
    },

}
</script>