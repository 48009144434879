<template>
    <div v-if="code == 'no_summary'">
        <img src="/assets/spa/fail-envelop.svg" style="width: 150px;margin: 0 auto;"/>
        <div class="_wording-container">
            <span class="_title">Oh no! Not enough info.</span>
            <span class="_text">We couldn't get enough transactions out of your selected accounts.</span>
            <br/>
            <span class="_text">If this is your only account, unfortunately there's not much we can do. If you have another bank account, please try again with that one.</span>
        </div>
        <div class="_button-container" style="flex-direction: column;">
            <primary-button id="primary_nav" style="width: 100%;justify-content: center;margin-left: 0px;margin-bottom: 10px;" @click="changeBankAccount">Change Bank Account</primary-button>
            <secondary-button id="secondary_nav" style="width:100%" @click="cancel">Cancel</secondary-button>
        </div>
    </div>
    <div class="_error-upload_failed" style="margin-top:0px;display: flex;flex-direction: column;height: calc(100vh - 50px);justify-content: space-between;" v-else>
        <div></div>
        <div class="_wording-container">
            <img src="/assets/spa/error-ice-cream.svg" style="width: 250px;margin: 0 auto;">
            <span class="_title">There was an error!</span>
            <span class="_text">{{message}}</span>
        </div>
        <div class="_buttons" v-if="displayReportError">
            <primary-button id="primary_nav" type="button"
                            class="_online-banking"
                            style="margin-left:0px"
                            @click="tryAgain">
                <span>Try Again</span>
                <i class="material-icons" style="margin-left:8px">keyboard_arrow_right</i>
            </primary-button>
            <secondary-button id="secondary_nav" @click="redirectToFaqs">FAQ's</secondary-button>
        </div>
        <div class="_buttons" v-else>
            <primary-button id="primary_nav" type="button"
                            class="_online-banking"
                            style="margin-left:0px"
                            @click="tryAgain">
                <span>Try Again</span>
                <i class="material-icons" style="margin-left:8px">keyboard_arrow_right</i>
            </primary-button>
            <div style="display: flex;width: 100%;">
                <secondary-button id="secondary_nav" @click="redirectToFaqs" style="margin-right: 7px;width: 60% !important;">FAQ's</secondary-button>
                <secondary-button id="secondary_nav" @click="reportIssue">Report Error</secondary-button>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props:['message', 'retry','id','code','displayReportError'],
    created() {
      window.utag.view({
        event_name: 'page_view, event_error',
        page_name: 'vodapay: vodalend compare: error connecting to bank',
        page_section: 'vodalend compare',
        page_channel: 'mobile app',
        visitor_login_status: 'logged in',
        page_type: 'webview',
        event_error_name: this.message,
        event_error_code: this.code,
        event_error_type: this.message
      });
    },
    methods: {
        tryAgain(){
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: error connecting to bank',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: try again',
            });
            this.retry()
        },
        redirectToFaqs(){
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push('faqs/online')
        },
        reportIssue(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: error connecting to bank',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: report issue',
            });
            this.$store.dispatch('state/updateStatus','report-issue')
            this.$router.push({ name: 'report-issue',params: { type: 'online' } })
        },
    }
}   
</script>