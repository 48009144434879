<template>
    <div class="_spa-container">
        <div>
            <div class="_wording-container">
                <span class="_title">Report Issue</span>
                <span class="_text">If you’re having an issue with your application, please let us know so we can resolve it for you.</span>
            </div>
            <el-form v-model="issue">
                <el-form-item>
                    <span style="color: #A8ACB8;padding: 5px 15px;">Error description</span>
                    <el-input
                        style="background: #222739;border-radius: 8px;border: none;"
                        type="textarea"
                        :rows="4"
                        :min="4"
                        :max="4"
                        class="_message-box"
                        placeholder="Error description"
                        resize="none"
                        v-model="issue.message">
                    </el-input>
                </el-form-item>
                <el-form-item class="_bank-box" v-if="$route.params.partner">
                    <span style="margin-bottom: -33px;display: flex;margin-left: 10px;z-index: 2;font-size: 11px;color: #8A8D9C;">Please select the lender you chose.</span>
                    <el-select v-model="issue.partner" placeholder="Select" class="__bank-select">
                        <el-option
                        v-for="item in offers.matches"
                        :key="item.company_name"
                        :label="item.company_name"
                        :value="item.company_name">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="_button-container">
            <secondary-button id="secondary_nav" @click="goBack" style="width:40%">Back</secondary-button>
            <primary-button id="primary_nav" type="button" @click="reportIssue" style="width: 60%;margin-left:10px;justify-content: center;" :disabled="(issue && issue.message) || !reportedIssue">
                <button-loading-spinner v-if="loading"/> 
                <span v-else>Report Issue</span>
            </primary-button>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
        <template v-if="type">
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">Your report was submitted successfully. Someone will be getting in touch with you in the next 48 hours. Please tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <span style="text-align:center">Please tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <secondary-button id="secondary_nav" @click="closeIssue" style="width:100%;color:#C9CCD4">Close <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </template>
        <template v-else>
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">Thank You</span>
            <span style="text-align:center">Your report was submitted successfully. Someone will be getting in touch with you in the next 48 hours.</span>
            <secondary-button id="secondary_nav" @click="closeIssue" style="width:100%;color:#C9CCD4">Try Another Method <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </template>
        </el-dialog>
    </div>

</template>
<script>
import { mapGetters, mapState } from 'vuex'
import * as Sentry from "@sentry/vue";

export default {
    data(){
        return{
            issue:{
                message:'',
                partner:this.$route.params.partner
            },
            loading:false,
            reportedIssue:false,
            prevRoute: null,
            dialogVisible:false,
            type: this.$route.params.type ? this.$route.params.type : false
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    computed: {
        ...mapState({
            bank_account: state => state.banking.bank_account,
        }),
        ...mapGetters('state', {
            status: 'status',
            banks:'banks'
        }),
        ...mapGetters('application', {
            personalLoan:'personalLoan',
            offers:'offers',
        }),
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: report issue',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    watch: {
        'bank_account': {
            handler (item) {
                if (item.slug) {
                    this.issue.bank = item.slug
                }
            },
            immediate: true // make this watch function is called when component created
        },
        'offers': {
            handler (val) {
                if (val) {
                    console.log(val)
                }
            },
            immediate: true // make this watch function is called when component created
        },
        
    },
    methods:{
        changeUploadMethod(){
            this.$store.dispatch('state/updateStatus','select-bank')
            this.$router.push({ name: 'select-bank'})
        },
        closeDialog() {
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: report issue pop up',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: got it',
            });
            this.dialogVisible = false;
        },
        goBack(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: report issue',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: back',
            });
            this.$router.go(-1)
        },
        async closeIssue(){
            this.dialogVisible = false;
            await this.$router.push({name:'select-upload'})
        },
        async reportIssue(){
            try {
                window.utag.link({
                    event_name: 'ui_interaction',
                    page_name: 'vodapay: vodalend compare: report issue',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                    link_id: 'vodapay: vodalend compare: submit',
                });
                this.loading = true;
                let page_endpoint = window.location.origin + this.prevRoute.path;
                this.issue.page_endpoint = page_endpoint
                this.issue.first_name = this.personalLoan.first_name;
                this.issue.last_name = this.personalLoan.last_name;
                this.issue.id_number = this.personalLoan.id_number;
                await this.$store.dispatch('application/reportIssue', this.issue)   
                this.loading = false;
                this.reportedIssue = true;
                this.dialogVisible = true;
                window.utag.link({
                    event_name: 'ui_interaction',
                    page_name: 'vodapay: vodalend compare: report issue pop up',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                });
            } catch (error) {
                Sentry.captureException(error, {
                    tags: {
                        section: "ReportIssue - reportIssue"
                    }
                });
                console.log(error)
            }

        }
    }
}
</script>
<style lang="scss">
.__bank-select{
    width: 100% !important;
    z-index: 1;
    .el-input__inner{
        background: rgb(34, 39, 57) !important;
        border-radius: 6px !important;
        height: auto;
        padding-left: 10px !important;
        color: white !important;
        padding-top: 15px !important;
    }
}
._message-box{
    .el-textarea__inner{
        background: rgb(34, 39, 57) !important;
        border-radius: 8px;
        border: none;
        color: white;
    }
}
.el-select-dropdown{
    background: #222739 !important;
    border-color: #222739 !important;
}
.popper__arrow{
    border-bottom-color: #222739;
    color: #222739;
}
.el-select-dropdown__item{
    color: white;
}
.selected{
    color: #E60000 !important;
}
.popper__arrow{
    display: none !important;
}
.el-popper[x-placement^=bottom] .popper__arrow::after{
    border-bottom-color: #222739 !important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background-color: #393949;
}
._bank-box{
    .el-form-item__content{
        display: flex;
        flex-direction: column;
    }
}
.__report-success{
    
    .el-dialog{
        background: #393949;
    }
    .el-dialog__header{
        padding:none;
    }
    .el-dialog__body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 70px;
        }
        p{
           color: #C9CCD4;
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 16px;
            text-align: center;
            word-break: break-word;
        }
        button{
            border: none !important;
        }
    }
}

</style>