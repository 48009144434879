<template>
    <div class="_spa-container" style="height:calc(100vh - 34px)">
        <div class="_faq-statement-container" style="height:calc(100vh - 34px)">
            <div class="_swiper-container">
                <swiper class="swiper" :options="swiperOption" @slideChange="changeSwiperIndex" ref="mySwiper">
                    <swiper-slide>
                        <div class="_swiper-content">
                            <faq-1/>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="_swiper-content">
                            <faq-2/>
                        </div>
                    </swiper-slide>
                     <swiper-slide>
                        <div class="_swiper-content">
                            <faq-3/>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div>
                <div class="_swiper-text" v-if="currentActiveIndex == 0">
                    <span class="_s-header">
                        Compare loan options that suit your needs
                    </span>
                    <span class="_s-text">
                        Let us help you find the right loan by offering you an opportunity to compare loan offers before making your decision...
                    </span>
                </div>
                <div class="_swiper-text" v-else-if="currentActiveIndex == 1">
                    <span  class="_s-header">
                        Submit your documents
                    </span>
                    <span  class="_s-text">
                        Get access to the loan you need in a few quick and easy steps. Gather your documents and get ready to upload them. We will need some personal details and your latest 3 bank statements.
                    </span>
                </div>
                <div class="_swiper-text" v-else-if="currentActiveIndex == 2">
                    <span  class="_s-header">
                        View your loan offers and select the best option
                    </span>
                    <span  class="_s-text">
                        From the list of loan offers, select the best one for your needs and budget. Finish comparing by proceeding to apply from the lender with the best offer.
                    </span>
                </div>
                <div class="_swiper-bullets">
                    <div>
                        <span :class="currentActiveIndex == 0 ? '_bullet _active-bullet' : '_bullet'" @click="changeActive(0)"></span>
                        <span :class="currentActiveIndex == 1 ? '_bullet _active-bullet' : '_bullet'" @click="changeActive(1)"></span>
                        <span :class="currentActiveIndex == 2 ? '_bullet _active-bullet' : '_bullet'" @click="changeActive(2)"></span>
                    </div>
                </div>
            </div>
            <div class="_button-container">
                <primary-button type="button" id="primary_nav" @click="redirectToBanks" style="width: 100%;margin-left:0px;justify-content: center;">Get Started</primary-button>
            </div>
            
        </div>
    </div>
</template>
<script>
import Faq1 from '../svg/faq-1.vue'
import Faq2 from '../svg/faq-2.vue'
import Faq3 from '../svg/faq-3.vue'
export default {
    components:{
        'faq-1':Faq1,
        'faq-2':Faq2,
        'faq-3':Faq3
    },
    data(){
        return {
            clicked:false,
            errors:{},
            swiperOption: {
                effect: 'coverflow',
                grabCursor: false,
                centeredSlides: true,
                slidesPerView: 'auto',
                coverflowEffect: {
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows : true
                },
                pagination: {
                    el: '.swiper-pagination'
                }
            },
            currentActiveIndex:0,
        }
    },
    created() {
        window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: carousel 1',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
            cid: this.$route.query.cid,
        });
    },
    methods:{
        getStarted(){
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push({ name: 'faqs',params: { type: 'general' } })
        },
        redirectToBanks(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: carousel '  + (this.currentActiveIndex + 1),
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: get started',
            });
            this.$store.dispatch('state/updateStatus', 'application-method')
            // eslint-disable-next-line no-undef
            let isMiniApp = false;
            try{
                // eslint-disable-next-line no-undef
                if(my){
                    isMiniApp = true
                }
            }catch{
                isMiniApp = false   
            }
            if(isMiniApp){
                // eslint-disable-next-line no-undef
                my.navigateTo({url:'/pages/main/main'});
            }else{
                this.$router.push({ name: 'application-method'})
            }
        },
        changeSwiperIndex () { 
            this.currentActiveIndex = this.$refs.mySwiper.swiper.activeIndex;
            window.utag.view({
                event_name: 'page_view',
                page_name: 'vodapay: vodalend compare: carousel ' + (this.currentActiveIndex + 1),
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
            });
            console.log(this.currentActiveIndex);
        },
        changeActive(index){
            this.currentActiveIndex = index;
            this.$refs.mySwiper.swiper.activeIndex = index;
        },

    }
}
</script>