<template>
    <styled-form-wrapper>
        <el-form-item :class="currency ? 'fc-input currency-input' : loginForm ? 'fc-input login-input' : 'fc-input'" :label="label" :error="error" :prop="prop" > 
            <el-input
                v-model="model"
                @input="emit"
                @focus="focus"
                @blur="blur"
                :id="prop+'Input'"
                :readonly="readonly"
                :type="type"
                :name="name"
                :class="inputClass"
                :min="min"
                :pattern="currency && '[0-9]'"
                :placeholder="placeholder"
                :maxlength="maxlength ? maxlength : 30"
                :show-password="showPassword"
            >
            <span slot="prefix" v-if="currency">R</span>
            </el-input>
            <span slot="label">
                <el-popover
                    v-if="tooltip"
                    ref="popover"
                    placement="right"
                    width="300"
                    trigger="click"
                    v-model="visible">
                    <p style="word-wrap: break-word;word-break: keep-all;word-spacing: -1px;">{{tooltip}}</p>
                    <div style="text-align: center; margin: 0;justify-content: right;display:flex">
                        <primary-button @click="visible = false" style="font-size: 15px;height: auto;padding: 0px 10px;border-radius: 13px;">Got It</primary-button>
                    </div>
                </el-popover>
                <span >{{label}}</span>
                <i class="el-icon-warning-outline" v-if="tooltip" v-popover:popover style="color: white;transform: rotate(180deg);margin-left: 5px;font-weight: 700;"></i>
            </span>
            <span  v-if="prop == 'loan_amount_required' && !error" style="margin-top: -8px;display: flex;color: #C9CCD4;font-size: 10px;margin-left: 25px;">
                Enter an amount up to R350 000
            </span>
            <span slot="error" class="_error-item" slot-scope="error">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 12px;height: 12px;margin-right: 5px;">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 0C35.7025 0 46 10.2975 46 23C46 35.7025 35.7025 46 23 46C10.2975 46 0 35.7025 0 23C0 10.2975 10.2975 0 23 0ZM25.1562 25.5938C25.1562 26.146 24.7085 26.5938 24.1562 26.5938H21.8438C21.2915 26.5938 20.8438 26.146 20.8438 25.5938V10.3438C20.8438 9.79146 21.2915 9.34375 21.8438 9.34375H24.1562C24.7085 9.34375 25.1562 9.79147 25.1562 10.3438V25.5938ZM23 30.1875C21.4122 30.1875 20.125 31.4747 20.125 33.0625C20.125 34.6503 21.4122 35.9375 23 35.9375C24.5878 35.9375 25.875 34.6503 25.875 33.0625C25.875 31.4747 24.5878 30.1875 23 30.1875Z" fill="#EB9700"/>
                </svg>
                {{error.error}}
            </span>
        </el-form-item>
    </styled-form-wrapper>
</template>
<script>
import { StyledFormWrapper } from './style'
export default {
    props: ['value', 'label', 'error', 'prop','readonly', 'type','name', 'inputClass','min','maxlength', 'currency','loginForm','placeholder','showPassword', 'setFocus','disabled','prevent','tooltip'],
    components:{
        'styled-form-wrapper': StyledFormWrapper
    },
    data() {
        return {
            visible:false,
            model: this.value,
            state: (this.value) ? '' : 'empty'
        }
    },
    watch: {

        value() {
            if(this.readonly){
                this.model = this.value
                if(this.name == 'phone_number'){
                    this.model = `+27 ${this.value.slice(1,3)} ${this.value.slice(3,6)} ${this.value.slice(6,10)}`  
                }else if(this.name == 'id_number'){
                    this.model = `${this.value.slice(0,2)} ${this.value.slice(2,4)} ${this.value.slice(4,6)} ${this.value.slice(6,9)} ${this.value.slice(9,13)}`    
                }
            }else{
                this.model = this.value
            }
            this.state = (this.value) ? '' : 'empty'
        }
    },
    methods: {
        getClass() {
            return (this.value) ? '' : 'empty'
        },
        focus() {
            this.state = 'focus';
            if(this.prop == 'employment_sector' || this.prop == 'employment_province' || this.prop == '' || this.prop == 'employment_length' || this.prop == 'loan_reason'){
                document.getElementById(this.prop+'Input').blur();
            }
            if(typeof this.setFocus !== 'undefined' ){
                this.setFocus(this.prop)
            }
        },

        blur() {
            this.state = (this.value) ? '' : 'empty'
            if(this.currency){
                try {
                    if(parseFloat(this.model)){
                        let model = (Math.round(this.model * 100) / 100).toFixed(2);
                        this.model = model
                        this.$emit('input', model)
                    }

                } catch (error) {
                    this.$emit('input', this.model)
                }

  
            }


        },

        emit(){
            let model = this.model;
            if(this.prop == 'phone_number'){
                if(model == "+27" || model == "27"){
                    model = "0"
                }
                model = model.replace(/ /g,'')
                if(model.length > 3){
                    model = model.substr(0,3)+' '+model.substr(3,3)+' '+model.substr(6,4)
                }
                model = model.trim()
            }

            this.model = model
            this.$emit('input', model)
        }
    }
}
</script>
