<template>
    <div class="_spa-container _statement-content">
        <div v-if="edittingIncome">
            <div class="_wording-container">
                <span class="_title">Confirm Income Details</span>
                <span class="_text">Please check that the below details which we retrieved from your statements are correct.</span>
            </div>
            <el-form :model="transactionForm" class="_input-form" :rules="rules" ref="income-requirements">
                <fc-form-input
                    label="Monthly income after taxes:"
                    prop="income"
                    :error="errors.income"
                    v-model="transactionForm.income"
                    :currency="true"
                    inputmode="numeric"
                    type="tel"
                    pattern="[0-9]*"
                    name="income"
                ></fc-form-input>
                <fc-form-input
                    label="Main Income source:"
                    prop="income_source"
                    :error="errors.income_source"
                    v-model="transactionForm.income_source"
                    name="income_source"
                ></fc-form-input>
            </el-form>
        </div>
        <div v-else>
            <div class="_wording-container">
                <span class="_title">Confirm Main Income</span>
                <span class="_text">Please select from the options below which is your main income.</span>
            </div>
            <div class="_accounts-info-container" style="margin-bottom:20px">
                <div class="_custom-radio" style="margin-top: 30px;">
                    <div v-for="transaction in creditTransactions" :key="transaction.id" class="_radio" style="border-bottom: 1px solid #a8acb8;padding-bottom: 10px;padding-top: 10px;;">
                            <div class="_account-details">
                                <span class="_thin" style="font-size:15px">R{{transaction.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}</span>
                                <span class="_bold">{{transaction.description}}</span>
                            </div>
                        <div>
                            <img class="_tick-svg" src="/assets/spa/tick-on.svg" v-if="selectedTransaction == transaction.id" @click="selectTransaction(transaction)"/>
                            <img class="_tick-svg" src="/assets/spa/tick-off.svg" v-else @click="selectTransaction(transaction)"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div style="display: flex;width: 100%;justify-content: space-between;">
                <secondary-button id="secondary_nav" type="button" style="width: 40%;" @click="changeIncomeSource" v-if="canChange">Change</secondary-button>
                
                <primary-button v-if="edittingIncome" id="primary_nav" type="button" :style="creditTransactions.length > 1 ? 'width: 60%;margin-left:10px;justify-content: center;' : 'width: 100%;margin-left:0px;justify-content: center;'" @click="confirmIncome">That is Correct</primary-button>
                <primary-button v-else id="primary_nav" type="button" style="width: 100%;margin-left:10px;justify-content: center" @click="submitIncomeChange">Confirm</primary-button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import { mapFields, validateAmount } from '../helpers.js' 

export default {
    data(){
        return {
            selectedTransaction:'',
            edittingIncome: true,
            errors:{},
            rules: {
                income: [
                    { required: true, validator: validateAmount, trigger: "blur" },
                ],
                income_source: [
                    { required: true, trigger: "blur" },
                ]
            },
        }
    },
    computed: {
        ...mapState({
            transactionForm: state => state.application.transactionData,
            creditTransactions: state => state.banking.creditTransactions
        }),
        ...mapGetters('application', {
            bankAccount: 'bankAccount'
        }),
        ...mapFields({
            fields: ['income','income_source'],
            store: 'application',
            fieldMap: 'transactionData',
            target: 'transactionForm',
            mutation: 'updateTransactionDataField'
        }),
        canChange() {
            return this.creditTransactions.length > 1;
        }
    },
    methods:{
        selectTransaction(transaction) {
            this.selectedTransaction = transaction.id
        },
        submitIncomeChange() {
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: confirm income details',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: confirm',
            });
            this.edittingIncome = true;
        },
        changeIncomeSource() {
            // tag before page "change"
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: confirm income details',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: change',
            });
          
            this.edittingIncome = false;
          
            // "page" changes
            window.utag.view({
                event_name: 'page_view',
                page_name: 'vodapay: vodalend compare: change main income',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
            });
        },

        confirmIncome(){
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: confirm income details',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: that\'s correct',
            });
            this.$refs['income-requirements'].validate(async valid => {
                if (valid) {
                    this.$router.push({name:'update-expenses'})
                } else {
                    this.errors.income = ''
                }
            })
        },
    }
}
</script>