import ApplicationMethod from './views/ApplicationMethod'
import Start from './views/Start'
import LoanInformation from './views/LoanInformation'
import PrimaryIncome from './views/PrimaryIncome'
import PersonalInformation from './views/PersonalInformation'
import Consent from './views/Consent'
import DisplayOffers from './views/DisplayOffers'
import FinalizeOffer from './views/FinalizeOffer'
import SelectBank from './views/SelectBank'
import SelectUpload from './views/SelectUpload'
import OnlineBanking from './views/OnlineBanking'
import Faqs from './views/Faqs'
import IncomeConfirmed from './views/IncomeConfirmed'
import Unemployed from './views/Unemployed'
import NoOffers from './views/NoOffers'
import EmploymentInformation from './views/EmploymentInformation'
import ReportIssue from './views/ReportIssue'
import Iframe from './views/Iframe'
import SuccessApplication from './views/SuccessApplication'
import TechnicalError from './views/TechnicalError'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import TermsAndConditions from './views/TermsAndConditions.vue'
import ThankYou from './views/ThankYou.vue'
import DuplicateEmail from './views/DuplicateEmail.vue'
import StatementUpload from './views/StatementUpload.vue'
import SelectIncome from './views/SelectIncome.vue'
import SelectExpenses from './views/SelectExpenses.vue'
import RedirectUrl from './views/RedirectUrl.vue'
import RedirectUpdate from './views/RedirectUpdate.vue'
import RedirectSuccess from './views/RedirectSuccess.vue'

export default [
  {
    label: 'Start',
    path: `/start`,
    name: 'start',
    component: Start,
    meta: { title: 'VodaLend Compare' },
  },
  {
    label: 'Start',
    path: `/iframe`,
    name: 'iframe',
    component: Iframe,
    meta: { title: 'Iframe' },
  },
  {
    label: 'Application Method',
    path: `/application-method`,
    name: 'application-method',
    component: ApplicationMethod,
    meta: { title: 'VodaLend Compare' },
  },
  {
    label: 'Loan Information',
    path: `/loan-information`,
    name: 'loan-information',
    component: LoanInformation,
    meta: { title: 'Get Loan Offers' },
  },
  {
    label: 'Primary Income',
    path: `/primary-income`,
    name: 'primary-income',
    component: PrimaryIncome,
    meta: { title: 'Get Loan Offers' },
  },
  {
    label: 'Personal Information',
    path: `/personal-information`,
    name: 'personal-information',
    component: PersonalInformation,
    meta: { title: 'Compare Personal Loans' },
  },
  {
    label: 'Consent',
    path: `/consent`,
    name: 'consent',
    component: Consent,
    meta: { title: 'Credit Enquiry Permission' },
  },
  {
    label: 'Display Offer',
    path: `/offers`,
    name: 'display-offers',
    component: DisplayOffers,
    meta: { title: 'View Loan Estimates' },
  },
  {
    label: 'Finalize Offer',
    path: `/finalize`,
    name: 'finalize-offer',
    component: FinalizeOffer,
    meta: { title: 'Loan Estimate Summary' },
  },
  {
    label: 'Select Bank',
    path: `/select-bank`,
    name: 'select-bank',
    component: SelectBank,
    meta: { title: 'Bank Statements' },
  },
  {
    label: 'Select Upload',
    path: `/select-upload`,
    name: 'select-upload',
    component: SelectUpload,
    meta: { title: 'Get Loan Offers' },
  },
  {
    label: 'Online Banking',
    path: `/online-banking`,
    name: 'online-banking',
    component: OnlineBanking,
    meta: { title: 'Internet Banking' },
  },
  {
    label: 'Faqs',
    path: `/faqs/:type`,
    name: 'faqs',
    component: Faqs,
    meta: { title: 'Faqs' },
  },
  {
    label: 'IncomeConfirmed',
    path: `/income-confirmed`,
    name: 'income-confirmed',
    component: IncomeConfirmed,
    meta: { title: 'Bank Statements' },
  },
  {
    label: 'Unemployed',
    path: `/unemployed`,
    name: 'unemployed',
    component: Unemployed,
    meta: { title: 'You Must Be Employed' },
  },
  {
    label: 'No Offers',
    path: `/no-offers`,
    name: 'failed-offers',
    component: NoOffers,
    meta: { title: 'No Offers Found' },
  },
  {
    label: 'Employment Information',
    path: `/employment`,
    name: 'employment',
    component: EmploymentInformation,
    meta: { title: 'Get Loan Offers' },
  },
  {
    label: 'Report Issue',
    path: `/report-issue/:partner`,
    name: 'report-issue',
    component: ReportIssue,
    meta: { title: 'Report Issue' },
  },
  {
    label: 'Report Issue',
    path: `/report-issue`,
    name: 'report',
    component: ReportIssue,
    meta: { title: 'Report Issue' },
  },
  {
    label: 'Success SMS',
    path: `/success/:type`,
    name: 'success',
    component: SuccessApplication,
    meta: { title: 'Success' },
  },
  {
    label: 'We Are Sorry',
    path: `/technical-error`,
    name: 'technical-error',
    component: TechnicalError,
    meta: { title: 'We Are Sorry' },
  },
  {
    label: 'Privacy Policy',
    path: `/privacy-policy/:partner`,
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: { title: 'Privacy Policy' },
  },
  {
    label: 'Terms And Conditions',
    path: `/terms-and-conditions/:partner`,
    name: 'terms-and-conditions',
    component: TermsAndConditions,
    meta: { title: 'Terms And Conditions' },
  },
  {
    label: 'Thank You',
    path: `/thank-you`,
    name: 'thank-you',
    component: ThankYou,
    meta: { title: 'Thank You' },
  },
  {
    name: 'duplicate-email',
    component: DuplicateEmail,
    path: `/duplicate-email`,
    label: 'Duplicate Email',
  },
  {
    name: 'upload-statements',
    component: StatementUpload,
    path: `/upload-statements`,
    label: 'Statement Upload',
    meta: { title: 'Bank Statements' },
  },
  {
    name: 'select-income',
    component: SelectIncome,
    path: `/select-income`,
    label: 'Select Income',
    meta: { title: 'Select Income' },
  },
  {
    name: 'update-expenses',
    component: SelectExpenses,
    path: `/update-expenses`,
    label: 'Update Expenses',
    meta: { title: 'Update Expenses' },
  },
  {
    name: 'redirect-url',
    component: RedirectUrl,
    path: `/redirect-url`,
    label: 'Redirect Url',
  },
  {
    name: 'redirect-update',
    component: RedirectUpdate,
    path: `/redirect-update`,
    label: 'Redirect Update',
  },
  {
    name: 'redirect-success',
    component: RedirectSuccess,
    path: `/redirect-success`,
    label: 'Redirect Success',
  },
];
