import api from "../../api";
import * as Sentry from "@sentry/vue";

const initialState = {
  personalLoan: {
    fast_application:true,
    loan_amount_required: null,
    repayment_period: null,
    payment_frequency: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    id_number: "",
    cell_phone_number: "",
    email: "",
    gross_income: null,
    net_income: null,
    income_source: null,
    expenses: null,
    popi: false,
    initial_popi: false,
    intent_id: 2,
    bank_name: "other",
    payday: 1,
    employed: true,
    bank: false,
    type: "",
    upload_method: "online",
    employer: "",
    employment_sector: "",
    employment_time: "",
    employment_cell_phone: "",
    employment_province: "",
    loan_reason: "",
  },
  type: "apply-now",
  

  gathrCustomer: {},
  errors: {},

  bankAccount: {
    id: "",
    tenant: "",
    api_token: "",
    repeat:false
  },
  transactionData:{
    income_source:null,
    income:null,
    expenses:null,
    month:null
  },
  selectedBank: null
};

// initial state
const state = () => ({
  ...initialState,
});
const getters = {
  personalLoan: (state) => state.personalLoan,
  type: (state) => state.type,
  bankAccount: (state) => state.bankAccount,
  selectedBank: (state) => state.selectedBank,
};
const actions = {
  async initialState() {},
  updatePersonalLoanField({ commit }, { key, value }) {
    commit("updatePersonalLoanField", { key, value });
  },
  
  updateType({ commit }, type) {
    commit("updateType", type);
  },

  updateSelectedBank({ commit }, selectedBank) {
    commit("updateSelectedBank", selectedBank);
  },


  // eslint-disable-next-line no-unused-vars
  async reportIssue({commit},payload){
    try {
        return await api.reportIssue(payload)
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "application - reportIssue"
        }
      });
      console.log(error)
    }
  },

  async createBankAccount({ state }) {
    let user = state.gathrCustomer;
    let result = await api.createBankAccount({
      customer_id: user.id,
      bank: state.personalLoan.bank,
      type: state.personalLoan.upload_method,
    });
    return result.data;
  },

  async createUser({ commit, state }){
    let user = false;
    try {
        let res = await api.getCustomer(state.personalLoan.id_number);
        res.data.forEach((item) => {
          if (item.id_number == state.personalLoan.id_number) {
            user = item;
          }
        });
        if (user) {
          let res = await api.updateCustomer(user.id, {
            id_number: state.personalLoan.id_number,
            first_name: state.personalLoan.first_name,
            last_name: state.personalLoan.last_name,
            email: state.personalLoan.email,
            middle_name: state.personalLoan.middle_name
          });
          user = res.data;
          commit("setGathrCustomer", user);
        } else {
          let res = await api.createCustomer({
            id_number: state.personalLoan.id_number,
            first_name: state.personalLoan.first_name,
            last_name: state.personalLoan.last_name,
            email: state.personalLoan.email,
          });
          user = res.data;
          commit("setGathrCustomer", user);
        }
        return res;   
    } catch (error) {
        let res = error.response;
        if(res.data && res.data.errors){
            let error = res.data.errors[0];
            if(error.includes('leads_email_unique')){
                return{
                    error:true,
                    type:'email',
                    message:'This email address is linked to another ID Number. Please update your email address or change your ID Number.'
                }
            }
        }else{
            return false
        }
    }
  }, 

  async setBankAccount({commit, state}, payload){
    let bankAccount = {
      id: payload.bankAccount.id,
      tenant: process.env.VUE_APP_GATHR_TENANT,
      api_token: state.gathrCustomer.api_token,
      repeat: payload.repeat
    };
    commit("setBankAccount", bankAccount);
  },

  async updateTransactionData({ commit }, payload){
    commit("setTransactionData",payload)
  }
};

const mutations = {
  updatePersonalLoanField(state, { key, value }) {
    state.personalLoan[key] = value;
  },
  updateTransactionDataField(state, { key, value }) {
    state.transactionData[key] = value;
  },
  setGathrCustomer(state, payload) {
    state.gathrCustomer = payload;
  },
  setBankAccount(state, bankAccount) {
    state.bankAccount = bankAccount;
  },
  updateType(state, type) {
    state.type = type;
  },
  updateSelectedBank(state, selectedBank) {
    state.selectedBank = selectedBank;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  confirmPopi(state, popi) {
    state.personalLoan.popi = popi;
  },
  setNetIncome(state, grossIncome) {
    state.net_income = grossIncome;
  },
  setTransactionData(state, txData){
    state.transactionData = txData
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
